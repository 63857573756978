import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as classes from "../SCSS/content.module.scss";
import * as styles from "../SCSS/case-studies.module.scss";

import Badge from "../components/Badge/Badge";
import { ArrowRight } from "phosphor-react";
import MainPageTemplate from "../components/Templates/PageTemplates/MainPageTemplate";

import { workRoute, work } from "../NavItems";
import useTopicFilter from "../../CustomFilterHook";

const MyWorkPage = () => {
  const { filterOptions, filteredTopicIndex, TopicDropdown } = useTopicFilter();

  // Contentful graphql api call
  const data = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy(sort: { order: DESC, fields: startDate }) {
        nodes {
          slug
          topics {
            topics
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          startDate(formatString: "MMM 'YY")
          endDate(formatString: "MMM 'YY")
          coverImage {
            gatsbyImageData(aspectRatio: 1.777777)
            title
          }
          title
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  `);

  // Date formatting..
  const dateString = new Date();
  const month = dateString.getMonth();
  const year = dateString.getFullYear().toString().slice(2, 4);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${monthNames[month]} '${year}`;

  return (
    <MainPageTemplate
      selected={work}
      pageTitle={work}
      pageHeading="Case Studies."
      pageDescription={`Check out some of my recents case studies that incorporate UX Design, visual design, user research and front-end development.`}
    >
      <section className={[styles.caseStudiesContainer].join(" ")}>
        <TopicDropdown />
        {data.allContentfulCaseStudy.nodes
          .filter((el) => {
            if (
              filteredTopicIndex === 0 ||
              el.topics.topics.includes(filterOptions[filteredTopicIndex].label)
            ) {
              return el;
            }
          })
          .map((caseStudy, i) => {
            const image = getImage(caseStudy.coverImage);

            return (
              <a
                href={`${workRoute}/${caseStudy.slug}`}
                className={styles.caseStudy}
              >
                <div className={styles.caseStudyImageContainer}>
                  <GatsbyImage
                    objectFit="contain"
                    objectPosition="center"
                    image={image}
                    loading="lazy"
                    alt={caseStudy.coverImage.title}
                  ></GatsbyImage>
                </div>
                <div className={styles.caseStudyInfoSection}>
                  <h2 className={styles.caseStudyHeading}>
                    {caseStudy.title}{" "}
                    <ArrowRight
                      weight="bold"
                      className={styles.caseStudyHeadingIcon}
                    />
                  </h2>
                  <p className={styles.caseStudyDescription}>
                    {caseStudy.description.childMarkdownRemark.rawMarkdownBody}
                  </p>
                  <div className={styles.metadataContainer}>
                    <div className={styles.badges}>
                      {caseStudy.topics.topics.map((area, i) => (
                        <Badge badgeLabel={area} variant={area} key={i + 1} />
                      ))}
                    </div>
                    <p className={classes.bodyText}>
                      {caseStudy.startDate} to{" "}
                      {caseStudy.endDate ? caseStudy.endDate : formattedDate}
                    </p>
                  </div>
                </div>
              </a>
            );
          })}
      </section>
    </MainPageTemplate>
  );
};

export default MyWorkPage;
