import React from "react";
import * as classes from "./Badge.module.scss";

export default function Badge(props) {
  let badgeClasses = [classes.badge];
  if (props.variant === "UI Design") badgeClasses.push(classes.badgeUI);
  if (props.variant === "UX Design") badgeClasses.push(classes.badgeUX);
  if (props.variant === "Front-end Development")
    badgeClasses.push(classes.badgeFE);
  if (props.variant === "User Research") badgeClasses.push(classes.badgeUR);
  if (props.badgeType === "Project") badgeClasses.push(classes.typeBadge);

  return <div className={badgeClasses.join(" ")}>{props.badgeLabel}</div>;
}

// export function TypeBadge(props) {
//   return <div className={classes.typeBadge}>{props.badgeLabel}</div>;
// }
