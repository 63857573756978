// extracted by mini-css-extract-plugin
export var badges = "case-studies-module--badges--UNrrD";
export var caseStudiesContainer = "case-studies-module--case-studies-container--m+lZR";
export var caseStudy = "case-studies-module--case-study--wIupI";
export var caseStudyDescription = "case-studies-module--case-study-description--Nj-kD";
export var caseStudyHeading = "case-studies-module--case-study-heading--3n4ED";
export var caseStudyHeadingIcon = "case-studies-module--case-study-heading-icon--XSf7h";
export var caseStudyImageContainer = "case-studies-module--case-study-image-container--jPAzb";
export var caseStudyInfoSection = "case-studies-module--case-study-info-section--fNqDV";
export var dissolveIn = "case-studies-module--dissolveIn--wQ49E";
export var metadataContainer = "case-studies-module--metadata-container--M7UdF";